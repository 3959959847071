<template>

</template>

<script>
import request from '../request/index'
export default {
	name: "VCSAccounts",
	mounted() {
		this.createAccount()
	},
	methods: {
		createAccount() {
			request.post(process.env.VUE_APP_BASE_URL  + '/vcs/add_account?code=' + this.$route.query.code + '&state=' + this.$route.query.state)
				.then(response => response.json())
				.then(data => {
					console.log(data)
				})
		}
	}
}
</script>

<style scoped>

</style>